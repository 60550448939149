import './LegendItem.css';

const LegendItem = ({color, text, icon}) => {

    return (
        <div className='legenditem'>
            
            <div className='legenditem-key' style={{ backgroundColor: color}}>
                <span className='legenditem-icon'>{icon}</span>
            </div>
            <div>{text}</div>
        </div>
    )
};

export default LegendItem;