import './Title.css';

const Title = () => {

    return (
        <span className='title'>
            <a href="/">Scorecards Prototype</a>
        </span>
    )
};

export default Title;