import Tooltip from '@mui/material/Tooltip';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import './HomeIcon.css';

const HomeIcon = () => {

    return (
        <>
            <div className='homeicon'>
                <Tooltip title='Home Tooltip'>
                    <a href='/'>
                        <HomeOutlinedIcon fontSize='large' />
                    </a>
                </Tooltip>
            </div>
        </>
    )
};

export default HomeIcon;