import React, { useState } from 'react';
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import CircularProgress from '@mui/material/CircularProgress';

import Footer from '../components/Footer';

import './WaitingList.css';

const WaitingList = () => { 

  const [ url ] = useState(`${FETCH_PATH}/profile`);
  const { data, isPending, error } = useFetchJson(url);

  return (

    <>
      <div className='waitinglist-fullpage'>
        <div className='waitinglist-content'>
        
          { isPending && <div>Loading... <CircularProgress size='1em'/></div> }
          { error && <div>{error}</div> }

          { data && <><div>
              <h2>Waiting List</h2>
              Welcome {data.user_data.given_name ?? data.user_data.sub}! <br></br>
              <br></br>
              We are reviewing your request to access this service.<br></br>
              <br></br>
              <br></br>
              <a href='/logout'>Logout</a>
              </div>
            </> 
          }
        </div>
        <Footer />
      </div>
    </>
    
  );
};

export default WaitingList;
