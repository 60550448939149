import LegendItem from "./LegendItem";
import { SEVERITY_TO_COLOR, SEVERITY_TO_ICON } from '../utils/Constants';

import './Legend.css';

const Legend = (color, title) => {

    return (
        <div className='legend'>
            <LegendItem color={SEVERITY_TO_COLOR[2]} icon={SEVERITY_TO_ICON[2]} text='Consistently valid in the evaluated IFC files' />
            <LegendItem color={SEVERITY_TO_COLOR[4]} icon={SEVERITY_TO_ICON[4]} text='Not consistently valid in the evaluated IFC files' />
            <LegendItem color={SEVERITY_TO_COLOR[3]} icon={SEVERITY_TO_ICON[3]} text='Valid but with warnings' />
            <LegendItem color={SEVERITY_TO_COLOR[0]} icon={SEVERITY_TO_ICON[0]} text='Not supported based on the evaluated IFC files' />
        </div>
    )
};

export default Legend;