import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import './SearchBar.css';

const SearchBar = () => {

    const [value, setValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [ url ] = useState(`${FETCH_PATH}/authoringtool`);
    const { data, isPending, error } = useFetchJson(url);
    
    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
        (async () => {
          setLoading(true);
          //await sleep(1000); // For demo purposes.
          setLoading(false);
          if (data) { 
            setOptions([...data.sort((t1, t2) => t1.full_name > t2.full_name ? 1 : -1)]);
          } else {
            setOptions([]);
          }
        })();
      };

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
      };

    const handleChange = async (event, newValue) => {
        
        // console.log('event ', event)
        // console.log('changed to ', newValue)        
        if (newValue) {
            navigate(`../scorecard/${newValue.id}`);
            navigate(0); // hack
        }
    }

    const theme = createTheme({
        typography: {
            fontSize: 12,
          }
      });

    return (
        <>
            <div className='searchbar'>
            <ThemeProvider theme={theme}>
                <Autocomplete
                    size="small"
                    sx={{ width: 300 }}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        handleChange(event, newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.full_name === value.full_name}
                    getOptionLabel={(option) => option ? option.full_name : '-'}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Search Application"
                        size="small"
                        slotProps={{
                            input: {
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                <SearchOutlinedIcon />
                                </React.Fragment>
                            ),
                            },
                        }}
                        />
                    )}
                />
            </ThemeProvider>
            </div>            
        </>
    )
};

export default SearchBar;