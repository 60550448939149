import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './SidePanel.css';

const SidePanel = ({data, isPending, error}) => {

    console.log(data)

    return (
        <>
            <div className='sidepanel'>

                { isPending && <div>Loading...<CircularProgress size='1em'/></div> }
                { error && <div>ERROR: {error}</div> }

                { data && <div className='sidepanel-content'>

                    <Tooltip title='Info Tooltip'>
                        <InfoOutlinedIcon />
                    </Tooltip>
                    <br />

                    <label className='sidepanel-label' htmlFor="label1">Company</label>
                    <label id="label1">{data.tool.company ? data.tool.company.name : '-'}</label>

                    <label className='sidepanel-label' htmlFor="label2">Application</label>
                    <label id="label2">{data.tool.name}</label>

                    <label className='sidepanel-label' htmlFor="label3">Version</label>
                    <label id="label3">{data.tool.version ?? '-'}</label>

                    <label className='sidepanel-label' htmlFor="label4">Last Evaluated</label>
                    <label id="label4">{data.info.last_evaluated ? new Date(data.info.last_evaluated).toLocaleDateString('en-GB', { year: "numeric", month: "short", day: "numeric" }) : '-'}</label>

                    <label className='sidepanel-label' htmlFor="label6">Total Evaluated File(s)</label>
                    <label id="label6">{data.info.tot_files}</label>

                    <label className='sidepanel-divider'></label>

                    <label className='sidepanel-label' htmlFor="label7">Selected Evaluated File(s)</label>
                    <label id="label7">{data.info.num_files}</label>

                    <label className='sidepanel-label' htmlFor="label5">Validation Service Version</label>
                    <label id="label5">{data.info.all_vs_versions && data.info.all_vs_versions.length > 0 
                        ? data.info.all_vs_versions.sort().map((version, _) => (
                            <div>
                                <label><input type='checkbox' name={version} checked={data.info.vs_version.includes(version)} disabled />&nbsp; {version}</label>
                            </div>
                        )) 
                        : '-'}
                    </label>

                    <label className='sidepanel-label' htmlFor="label8">IFC Schema Version</label>
                    <label id="label8">{data.info.all_ifc_schemas && data.info.all_ifc_schemas.length > 0 
                        ? data.info.all_ifc_schemas.sort().map((schema, _) => (
                            <div>
                                <label><input type='checkbox' name={schema} checked={data.info.ifc_schema.includes(schema)} disabled />&nbsp; {schema}</label>
                            </div>
                        )) 
                        : '-'}
                    </label>
                    
                    </div>}

            </div>
        </>
    )
};

export default SidePanel;
