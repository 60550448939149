import { useState, useEffect } from "react";

export const useFetchJson = (url) => {

  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchData = async () => {

      setIsPending(true);
            
      try {

        const response = await fetch(url, { credentials: 'include' });
        if (!response.ok) throw new Error(response.status + ' ' + response.statusText, { cause: response });
        const json = await response.json();
        setData(json);
        setIsPending(false);
        setError(null);

      } catch (error) {

        if (error && error.cause) {
          const contentType = error.cause.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            let json = await (error.cause).json();

            if (json.status_code === 401 && json.login_url) {
              const redirect_url = json.login_url;
              console.log('401 - Redirecting to ', redirect_url);
              window.location.replace(redirect_url);

            } else if (json.status_code === 403 && json.redirect_url) {
              const redirect_url = json.redirect_url;
              console.log('403 - Redirecting to ', redirect_url);
              window.location.replace(redirect_url);
            }

          } else {
            console.log('ERROR: ', error, await (error.cause).text());
          }
        }setError(`Error: Could not fetch data at ${url} - reason: ${error}`);
        setIsPending(false);
      }
    };
    fetchData();
  }, [url]);

  return { data, isPending, error };
};