import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Dashboard from './views/Dashboard';
import Dashboard2 from './views/Dashboard2';
import Scorecard from './views/Scorecard';
import WaitingList from './views/WaitingList';

import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard2" element={<Dashboard2 />} />
        <Route path="/scorecard/:id" element={<Scorecard />} />
        <Route path="/waitinglist" element={<WaitingList />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);