import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import { SEVERITY_TO_LABEL, SEVERITY_TO_ICON, SEVERITY_TO_COLOR } from '../utils/Constants';

import './Scorecard.css';
import { IconButton } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    //   maxWidth: 220,
    //   fontSize: theme.typography.pxToRem(12),
    //   border: '1px solid #dadde9',
    },
  }));

const Scorecard = ({ id, code, title, description, severity, span, children, isChild, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };
    
    return (
        <div style={{
            gridColumn: 'span ' + span,
            ...(expanded ? {border: 'dotted 1px gray'} : {})
        }}>
            <div className={`scorecard ${expanded ? ' expanded' : ''}`} onClick={() => {onClick(code)}} style={{
                cursor: 'pointer',
                ...(expanded ? {} : {backgroundColor: SEVERITY_TO_COLOR[severity]}),
                ...(isChild || expanded ? {marginBottom: '15px'} : {})
            }}>
                { children && children.length > 0 && 
                    
                        <IconButton onClick={handleToggle} style={{float: "right"}}>
                            <Tooltip title={expanded ? 'click to collapse': 'click to expand' } placement="top">
                                {expanded ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon /> }   
                            </Tooltip>
                        </IconButton>
                }
                <Tooltip title={description} placement="top">
                    { code && <span className="scorecard-code">{SEVERITY_TO_ICON[severity]} {code}</span> }
                    <span className="scorecard-title">{title}</span>
                </Tooltip>
                { children && children.length > 0 && <span className="scorecard-icon">
                    <HtmlTooltip placement="bottom" title={
                        <React.Fragment>
                            { children.map((c) => (
                                <>
                                ({c.code})&nbsp;{c.title}: {SEVERITY_TO_LABEL[c.severity]}
                                </>    
                            )) }
                        </React.Fragment>
                    }>
                    </HtmlTooltip></span> }
            </div>
            { expanded && children && children.length > 0 && children.map((child, index) => (
                <Scorecard {...child} isChild={true} key={`${id}/${index}`} onClick={onClick} />
            ))}
        </div>
    )
};

export default Scorecard;