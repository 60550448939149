import HomeIcon from "./HomeIcon";
import SearchBar from "./SearchBar";
import Title from "./Title";
import HelpIcon from "./HelpIcon";

import './Header.css';

const Header = () => {

    return (
        <>
            <div className='header'>
                <HomeIcon />
                <SearchBar />
                <Title />
                <HelpIcon />
            </div>
        </>
    )
};

export default Header;