import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import Scorecard from './Scorecard';

import './ScorecardGrid.css';

const ScorecardGrid = ({data, isPending, error, onItemClick}) => {
    return (
        <>
            <div className='scorecardgrid'>
                
                { isPending && <div>Loading... <CircularProgress size='1em'/></div> }
                { error && <div>ERROR: {error}</div> }
                
                <div className='scorecardgrid-content'>                
                { data && data.scorecards.map((card, index) => (
                        <Scorecard
                            id={index}
                            key={index}
                            onClick={onItemClick}
                            {...card}
                        >
                        </Scorecard>
                    ))}
                </div>
            </div>
        </>
    )
};

export default ScorecardGrid;