import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import './HelpIcon.css';

const HelpIcon = () => {

    return (
        <>
            <div className='helpicon'>
                <Tooltip title='Help Tooltip'>
                    <HelpOutlineOutlinedIcon />
                </Tooltip>
            </div>
        </>
    )
};

export default HelpIcon;