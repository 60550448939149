/*

OBSOLETE - can be deleted post-prototype

*/

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import CircularProgress from '@mui/material/CircularProgress';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

import Header from '../components/Header';
import Footer from '../components/Footer';

import './Dashboard2.css';

const Dashboard = () => { 

  const [ url ] = useState(`${FETCH_PATH}/authoringtool`);
  const { data, isPending, error } = useFetchJson(url);

  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);

  const navigate = useNavigate();

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
      if (itemId > 0) {
        navigate(`../scorecard/${itemId}`);
        navigate(0); // hack
      }
    }
  };

  function getFilesSuffix(count) {
    return count.toLocaleString() + ((count > 1) ? ' files' : ' file');
  }

  function getProducts(data) {

    let grouped = [];

    // dummy for items without company
    var filteredContent = data.map((el) => {
      if (el.company == null) {
        el.company = { name: '-Unknown Vendor-' };
      }
      return el;
    });

    // order all tools
    filteredContent.sort((f1, f2) => f1.company.name > f2.company.name ? 1 : -1);

    for (let c of (filteredContent || [])) {
      if (grouped.length === 0 || (c.company.name) !== grouped[grouped.length-1][0]) {
        grouped.push([c.company.name,[]])
      }
      grouped[grouped.length-1][1].push(c);
    }

    // order companies
    grouped.sort((c1, c2) => c1[0].toLowerCase() > c2[0].toLowerCase() ? 1 : -1);

    grouped = grouped.map((value, index) => {

      return {

        // company
        id: (-index).toString(), // prevent clash with tool id
        label: value[0] + ' - ' + getFilesSuffix(value[1].reduce( (a, b) => {
            return a + b.models_count;
        }, 0)),
        children: value[1].sort((t1, t2) => t1.name > t2.name ? 1 : -1).map((value2) => {
        
          // tool
          return {
            id: value2.id.toString(),
            label: value2.name + ' - ' + value2.version + ' - ' + getFilesSuffix(value2.models_count)
          }
        
        })
      }
    });

    return grouped;
}

  return (

    <>
      <div className='dashboard2-fullpage'>
        <Header />
        <div className='dashboard2-content'>
        
          { isPending && <div>Loading... <CircularProgress size='1em'/></div> }
          { error && <div>{error}</div> }
          { data && <h4>Authoring Tools</h4> }
          { data && <RichTreeView items={getProducts(data)} onItemSelectionToggle={handleItemSelectionToggle} /> }
        </div>
        <Footer />
      </div>
    </>
    
  );
};

export default Dashboard;
